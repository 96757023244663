<template>
  <div>
    <b-modal
      id="modal-payment"
      hide-header
      hide-footer
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      title="Large Modal"
      class="custom__modal"
      no-close-on-esc
      no-close-on-backdrop
    >
      <ModalHeader
        :left-text="'Kembali'"
        :middle-text="'Pilih Metode Pembayaran'"
        :left-function="directPage"
        :is-rigt-column="false"
        :right-function="createUtang"
        :isDisabled="payment_method !== 'cash' && payment_method !== 'transfer' && disabledButton"
        :right-text="'Simpan'"
      />
      <div class="form-payment mt-5 pt-5">
        <b-container>
          
          <div class="text-center" v-if="this.isSupplier">
            <div class="size14 fw-bold-600">Total Pembayaran</div>
            <div v-if="total > 0 && totalVoucher === 0">
                <h2 class="fw-bold-900 text-dark">{{ total | formatAmount }}</h2>
            </div>
            <div v-else-if="total === 0 && totalVoucher > 0">
                <h2 class="fw-bold-900 text-dark">{{ totalVoucher | formatAmount }}</h2>
                <div class="size14 fw-bold-600">Pembayaran hanya menggunakan voucher</div> 
            </div>
            <div v-else-if="total > 0 && totalVoucher > 0">
                <h2 class="fw-bold-900 text-dark">{{ total | formatAmount }}</h2>
                <div class="size14 fw-bold-600">Total voucher digunakan {{ totalVoucher | formatAmount }}</div>
            </div>
          </div>

          <div class="text-center" v-if="this.isCustomer">
            <div class="size14 fw-bold-600">Total Pembayaran</div>
            <h2 class="fw-bold-900 text-dark">{{ amount | formatAmount }}</h2>
          </div>

          <b-row class="justify-content-center align-items-center">
            <b-col cols="8">
              <b-row class="pt-5">
                <b-col
                  v-for="(item, index) in payments"
                  :key="index"
                  class="px-0"
                  cols="6"
                >
                  <b-card
                    class="p-2 m-1"
                    no-body
                    style="border-radius: 12px;"
                  >
                    <b-form-checkbox
                      v-model="payment_method"
                      :value="item.value"
                      class="custom-control-success custom__checkbox"
                    >
                      <div
                        role="button"
                        class="d-flex align-items-center"
                      >
                        <div class="d-flex justify-content-center align-items-center rounded-circle image-box box-tempo">
                          <b-img
                            :src="require(`@/assets/images/icons/${item.image}.svg`)"
                            blank-color="#ccc"
                            width="18"
                            alt="placeholder"
                          />
                        </div>
                        <div class="ml-1">
                          <h6 class="text-dark fw-bold-800">
                            {{ item.name }} <span class="text-danger">*</span>
                          </h6>
                          <h6 class="text-dark text-darken-4 fw-bold-500 size12">
                            Pembayaran Langsung
                          </h6>
                        </div>
                      </div>
                    </b-form-checkbox>
                    <div v-if="item.value == 'transfer'">
                      <Transition name="fade" mode="out-in">
                        <b-collapse
                          v-if="payment_method == 'transfer'"
                          id="collapse-2-inner"
                          visible
                          class=""
                        >
                          <b-row>
                            <b-col cols="2" />
                            <b-col cols="10">
                              <hr>
                              <div>
                                <b-form-datepicker
                                  id="example-datepicker"
                                  v-model="payment_date"
                                  class="mb-1"
                                  locale="id"
                                  placeholder="Pilih tanggal"
                                />
                              </div>
                            </b-col>
                          </b-row>
                        </b-collapse>
                      </Transition>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
    <b-modal
      id="modal-form-payment"
      centered
      hide-footer
      size="lg"
      @hide="$bvModal.hide('modal-form-payment')"
    >
      <template #modal-header>
        <h5 class="modal-title">Detail Pembayaran</h5>
        <button type="button" aria-label="Close" class="p-0 size24 fw-bold-600 border-0 bg-gray-5" @click="$bvModal.hide('modal-form-payment')">x</button>
      </template>
      <Giro v-if="payment_method == 'giro'" @formPayment="getFormPayment" :createUtang="createUtang" />
      <Kredit v-if="payment_method == 'card'" @formPayment="getFormPayment" :createUtang="createUtang" />
      <TransferBank v-if="payment_method == 'transfer'" @formPayment="getFormPayment" :createUtang="createUtang" />
    </b-modal>
    <InputAmount
      :title="$store.state.supplier.total"
      :subtitle="'Uang diterima'"
      :createUtang="createUtang"
    />
  </div>
</template>

<script>
import {
  BModal, BRow, BCol, BContainer, BFormCheckbox, BCard, BImg, BCollapse, BFormDatepicker, BButton
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification, preformatFloat } from '@/auth/utils'
import ModalHeader from '@/components/ModalHeader.vue'
import InputAmount from '@/components/Cashier/Modal/InputAmount.vue'
import Giro from '@/components/Payment/Giro/FormStep1.vue'
import Kredit from '@/components/Payment/Kredit/FormStep1.vue'
import TransferBank from '@/components/Payment/TransferBank/index.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BContainer,
    BFormCheckbox,
    BCard,
    BImg,
    BButton,
    ModalHeader,
    BCollapse,
    BFormDatepicker,
    InputAmount,
    Giro,
    Kredit,
    TransferBank,
  },
  props: {
    getUtang: {
      type: Function,
    },
    getPiutang: {
      type: Function,
    },
    total: {
      type: Number,
    },
    totalVoucher: {
      type: Number,
    },
    amount: {
      type: Number,
    },
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification, preformatFloat
    }
  },
  data() {
    return {
      payment_method: '',
      payment_date: '',
      payments: [
        {
          name: 'Tunai',
          value: 'cash',
          image: 'tunai',
          class: 'image-box box-tempo',
        },
        {
          name: 'Transfer Bank',
          value: 'transfer',
          image: 'transfer',
          class: 'image-box box-transfer',
        },
        {
          name: 'Kredit atau Debit',
          value: 'card',
          image: 'kredit',
          class: 'image-box box-kredit',
        },
        {
          name: 'Giro',
          value: 'giro',
          image: 'giro',
          class: 'image-box box-giro',
        },
      ],
      formPayload: {},
      paymentPayload: {},
      itemsPayload: {},
      customer_supplier_uuid: '',
      isCustomer: false,
      isSupplier: false,
      isLoading: false,
      disabledButton: true,
    }
  },
  watch: {
    '$store.state.supplier.formPayloadUtang': {
      handler(value) {
        this.itemsPayload = value.items.filter(e => e.uuid !== null) || []
        this.isSupplier = true
      },
      deep: true,
    },
    '$store.state.customer.formPayloadPiutang': {
      handler(value) {
        this.itemsPayload = value.items.filter(e => e.uuid !== null) || []
        this.isCustomer = true
      },
      deep: true,
    },
    payment_method(value) {
      if (value) {
        this.paymentPayload = {}
        if (value === 'cash') {
          this.paymentPayload = {}
          // delete this.formPayload.bank_name
          // delete this.formPayload.bank_account_name
          // delete this.formPayload.bank_account_number
          // delete this.formPayload.bank_account_payment_uuid
          // delete this.formPayload.bank_transfer_attachment
          this.$bvModal.show('modal-input-discount')
        } else if (value !== 'transfer') {
          this.$bvModal.show('modal-form-payment')
        }
      } else {
        this.formPayload.payment_date = ''
        this.payment_date = ''
      }
      // this.$store.commit('stockWarehouse/setPaymentMethod', value)
    },
    payment_date(value) {
      // this.formPayload.payment_date = value
      if (value) {
        this.$bvModal.show('modal-form-payment')
      }
      // this.$store.commit('stockWarehouse/setPaymentMethod', value)
    },
  },
  mounted() {
    this.customer_supplier_uuid = localStorage.getItem('customer_supplier_uuid')
    this.formPayload = this.$store.state.supplier.formPayloadUtang || this.$store.state.customer.formPayloadPiutang
    this.isSupplier = false
    this.isCustomer = false
    this.payment_date = ''
    this.payment_method = ''
  },
  destroyed() {
    this.isSupplier = false
    this.isCustomer = false
    this.payment_date = ''
    this.payment_method = ''
    this.paymentPayload = {}
    this.$store.commit('supplier/setFormPayloadUtang', {})
    this.$store.commit('customer/setFormPayloadPiutang', {})
  },
  methods: {
    createUtang() {
      const paymentType = this.isCustomer ? 'piutang' : 'utang';

      this.$swal({
        title: 'Konfirmasi',
        text: `Apa Anda yakin ingin melakukan pembayaran ${paymentType}?`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          // const formPayment = JSON.parse(localStorage.getItem('payloadPayment'))
          this.formPayload = this.paymentPayload
          this.formPayload.payment_method = this.payment_method
          this.formPayload.items = this.itemsPayload
          this.formPayload.cash_total_payment = localStorage.getItem('cash_total_payment')
          this.formPayload.payment_date = this.payment_date

          const form = this.preparePayload()

          if (this.isSupplier) {
            this.$store.dispatch('supplier/postUtang', {
              payload: form,
              supplierUuid: localStorage.getItem('customer_supplier_uuid'),
            }).then(() => {
              this.isLoading = false
              successNotification(this, 'Success', 'Utang berhasil dibayar')
              this.$bvModal.hide('modal-payment')
              this.$bvModal.hide('modal-debt')
              this.$store.commit('supplier/setCloseModal', true)
              this.isSupplier = false
              localStorage.removeItem('payloadPayment')
              localStorage.removeItem('cash_total_payment')
              localStorage.removeItem('price')
              // localStorage.removeItem("customer_supplier_uuid");
              this.$bvModal.hide('modal-form-payment')
              this.payment_method = ''
              this.payment_date = ''
              window.location.reload()
              this.getUtang()
            }).catch(err => {
              this.isSupplier = true
              this.isLoading = false
              console.log(err)
            })
          }
          if (this.isCustomer) {
            this.$store.dispatch('customer/postPiutang', {
              payload: form,
              customerUuid: localStorage.getItem('customer_supplier_uuid'),
            }).then(() => {
              this.isLoading = false
              successNotification(this, 'Success', 'Piutang berhasil dibayar')
              this.$bvModal.hide('modal-payment')
              this.$bvModal.hide('modal-piutang')
              localStorage.removeItem('cash_total_payment')
              localStorage.removeItem('price')
              this.payment_method = ''
              this.payment_date = ''
              this.$bvModal.hide('modal-form-payment')
              this.$store.commit('supplier/setCloseModal', true)
              this.isCustomer = false
              window.location.reload()
              this.getPiutang()
            }).catch(err => {
              this.isCustomer = true
              this.isLoading = false
              console.log(err)
            })
          }
        }
      });
    },
    getFormPayment(payload) {
      this.paymentPayload = payload
      localStorage.setItem('payloadPayment', JSON.stringify(payload))
      this.disabledButton = this.payment_method !== 'transfer' && this.checkObjectHasValue(this.paymentPayload)
    },
    checkObjectHasValue(object) {
      return Object.values(object).some(x => x === null || x === '')
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          if (key !== 'items') {
            form.append(key, this.formPayload[key])
          }
        }
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.formPayload.items.length; i++) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key of Object.keys(this.formPayload.items[i])) {
          if (key !== 'amount' && key !== 'voucher_uuid' && this.formPayload.items[i].uuid) {
            form.append(`items[${i}][${key}]`, this.formPayload.items[i][key])
          }
          if (key == 'amount' && this.formPayload.items[i].uuid) {
            form.append(`items[${i}][${key}]`, parseFloat(preformatFloat(this.formPayload.items[i][key].toString())))
          }
        }
        if (this.formPayload.items[i].voucher_uuid) {
          for (let j = 0; j < this.formPayload.items[i].voucher_uuid.length; j++) {
            const element = this.formPayload.items[i].voucher_uuid[j]
            form.append(`items[${i}][voucher_uuid][${j}]`, element)
            // console.log(element)
          }
        }
      }
      return form
    },
    directPage() {
      this.$bvModal.hide('modal-payment')
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
.form-payment {
  .custom-checkbox {
    .custom-control-label::after,
    .custom-control-label::before {
      right: 4%;
      top: 35%;
      left: inherit;
    }
    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: none !important;
    }
    .custom-control-label::before {
      border: none;
    }
  }
  .custom-control-label {
    width: 100%;
  }
  .custom-checkbox.custom-control {
    padding-left: 0;
  }
  .image-box {
    width: 46px;
    height: 46px;
  }
  .card {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
  }
}
#modal-payment {
  .modal-body {
    background: #F6F7FA;
  }
}
</style>
